<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}卫生服务团队</span>
		</div>
		<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="团队名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder=" 请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr16" label="负责人" prop="directorName">
				<el-input v-model.trim="ruleForm.directorName" :disabled="isShowDetail" placeholder=" 请输入"></el-input>
			</el-form-item>
			<el-form-item label="" class="w50 mr0" prop="phoneNumber">
				<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" placeholder=" 联系电话" maxlength="11"></el-input>
			</el-form-item>
			<el-form-item label="所属街道" prop="townCode" class="w50 mr16">
				<el-select v-model="ruleForm.townCode" placeholder="所属街道" :disabled="isShowDetail" @change="changeSelect($event, 'townCode')">
					<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="regionCode" label="所属社区" class="w50 mr0">
				<el-select
					v-model="ruleForm.regionCode"
					placeholder="所属社区"
					:disabled="isShowDetail || !ruleForm.townCode"
					@change="changeSelect($event, 'regionCode')"
				>
					<el-option v-for="item in communityList" :key="item.id" :label="item.title" :value="item.code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="添加成员" prop="medicalWorkerIdList">
				<div class="users-item" v-for="(item, index) in ruleForm.medicalWorkerIdList" :key="index">
					<el-select v-model="item.name" placeholder="姓名" @change="changeUser($event, item, index)" :disabled="isShowDetail">
						<el-option v-for="item in option" :disabled="!item.enabled" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
					<el-select v-model="item.agency" placeholder="机构" disabled>
						<el-option v-for="item in option" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
					<el-select v-model="item.type" placeholder="类型" disabled>
						<el-option v-for="item in option" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
					<i class="iconfont icon-tianjia3" @click="onNew(index)" v-if="!isShowDetail"></i>
					<i class="iconfont icon-a-shanchu2" @click="onDelete(item, index)" v-if="ruleForm.medicalWorkerIdList.length > 1 && !isShowDetail"></i>
				</div>
			</el-form-item>
			<el-form-item class="func-btn">
				<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
				<el-button v-else type="primary" @click="onSave('ruleForm')">保存</el-button>
				<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import { verifyPhone } from '@/utils/toolsValidate';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		RsForm: () => import('@/components/RsForm/index.vue'),
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		return {
			ruleForm: { medicalWorkerIdList: [{ name: '' }] },
			createdVisible: false,
			townList: [],
			communityList: [],
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				directorName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				townCode: [{ required: true, message: '请选择所属街道', trigger: 'change' }],
				regionCode: [{ required: true, message: '请选择所属社区', trigger: 'blur' || 'change' }],
				medicalWorkerIdList: [{ type: 'array', required: true, message: '请至少选择一个成员', trigger: 'change' }],
			},
			saveLoading: false,
			link: '',
		};
	},
	props: ['isShowDetail', 'option'],
	watch: {},
	created() {},
	methods: {
		init(params) {
			this.createdVisible = true;
			this.link = params.link;
			this.getTownList();
			if (params.row.id) {
				this.getDetails();
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取街道
		getTownList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href, {
					params: { current: 1, size: 9999, code: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[2].code },
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.townList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取详情
		getDetails() {
			this.$http
				.get(this.link)
				.then((res) => {
					if (res.data && res.data.success) {
						let data = res.data.collection[0] || {};
						this.ruleForm = JSON.parse(JSON.stringify(data));
						let townCode = (data.regionDtoList && data.regionDtoList.length > 0 && data.regionDtoList[3].code) || null;
						if (townCode) {
							this.$set(this.ruleForm, 'townCode', townCode);
							this.changeTown(townCode);
							this.ruleForm.regionCode = JSON.parse(JSON.stringify(data.region?.code));
						}
						this.ruleForm.medicalWorkerIdList = [];
						data.medicalWorkerIdList.forEach((item) => {
							let resInd = this.option.findIndex((iter) => iter.id === item.id);
							if (resInd !== -1) {
								this.option[resInd].enabled = false;
								this.ruleForm.medicalWorkerIdList.push({
									name: this.option[resInd].name,
									type: this.option[resInd].practiceCategoryDictionaryItem?.title,
									agency: this.option[resInd].medicalOrganization?.title,
									id: this.option[resInd].id,
								});
							}
						});
					}
				})
				.catch((e) => {});
		},
		// 保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						medicalWorkerIdList: this.ruleForm.medicalWorkerIdList.map((item) => item.id),
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								// this.uploadImg(res.data.collection[0].id);
								this.saveLoading = false;
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
							}
						})
						.catch(() => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		// 新增成员
		onNew(index) {
			this.ruleForm.medicalWorkerIdList.push({ name: '', type: '', agency: '', id: '' });
		},
		onDelete(item, index) {
			console.log(index, this.option);
			this.option.find((v) => v.id == item.id) ? (this.option.find((v) => v.id == item.id).enabled = true) : '';
			this.ruleForm.medicalWorkerIdList.splice(index, 1);
			this.$forceUpdate();
		},
		changeUser(id, item, index) {
			if (!id || id === '') return;
			const selectItem = this.option.find((it) => it.id == id);
			selectItem.enabled = false;
			selectItem.name = selectItem.name;
			selectItem.agency = selectItem.medicalOrganization?.title;
			selectItem.type = selectItem.practiceCategoryDictionaryItem?.title;
			this.ruleForm.medicalWorkerIdList[index] = JSON.parse(JSON.stringify(selectItem));
			this.option.forEach((v) => {
				let resInd = this.ruleForm.medicalWorkerIdList.findIndex((iter) => iter.id === v.id);
				if (resInd === -1) {
					v.enabled = true;
				}
			});
			this.$forceUpdate();
		},
		changeSelect(val, className) {
			this.ruleForm[className] = val;
			if (className == 'townCode') {
				this.changeTown(val);
			}
			if (className == 'regionCode') {
				this.$refs['ruleForm'].clearValidate('regionCode');
			}
			this.$forceUpdate();
		},
		changeTown(val) {
			this.communityList = [];
			this.ruleForm.regionCode = '';
			this.$http
				.get(this.api['ChinaRegions#index'].href, {
					params: { current: 1, size: 9999, code: val },
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.communityList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 重置
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.medicalWorkerIdList = [{ name: '' }];
			this.$refs[formName].resetFields();
		},
	},
};
</script>

<style lang="scss" scoped>
.showBorder {
	border: 1px solid red;
}
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
.users-item {
	margin-bottom: 12px;
	display: grid;
	grid-template-columns: 136px 136px 136px 16px 16px;
	gap: 15px;
	i {
		line-height: 32px;
		color: #999;
		font-size: 16px;
		cursor: pointer;
	}
	i:hover {
		color: #1db9b1;
	}
	.icon-shanchu:hover {
		color: #f56c6c;
	}
}
.users-item:nth-last-child(1) {
	margin-bottom: 0;
}
.func-btn /deep/ .el-form-item__content {
	display: flex;
	justify-content: center;
}
.flex-tow {
	display: flex;
	align-items: center;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
</style>